import { AuthRequest } from "@/api/axios";
import queryString from "query-string";
import { BASE_API_URL2 } from "@/const/index";

export const getStoreRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/stores${params ? `?${queryString.stringify(params)}` : ""}`,
    method: "GET",
  });
  return res;
};

export const getChangeCommissionLogRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/change-commission-log${params ? `?${queryString.stringify(params)}` : ""}`,
    method: "GET",
  });
  return res;
};

export const getExportStoreRequest = async () => {
  const res = await AuthRequest({
    url: "/api/export/stores",
    method: "GET",
  });
  return res;
};

export const createStore = async (data) => {
  const res = await AuthRequest({
    url: "/api/stores",
    method: "POST",
    data,
  });
  return res;
};

export const deleteStore = async (data) => {
  let idx = data?.idx;
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/stores/${idx}`,
    method: "DELETE",
    data,
  });
  return res;
};

export const updateStore = async (data) => {
  let idx = data?.idx;
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/stores/${idx}`,
    method: "PUT",
    data,
  });
  return res;
};

export const requestSettlement = async (data) => {
  const res = await AuthRequest({
    baseURL: BASE_API_URL2,
    url: '/transaction/settlement/request',
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    data,
  });
  return res;
};

export const getCommission = async (data) => {
  const res = await AuthRequest({
    baseURL: BASE_API_URL2,
    url: '/transaction/get/commission',
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    data,
  });
  return res;
};

export const requestUserDeposit = async (data) => {
  const res = await AuthRequest({
    baseURL: BASE_API_URL2,
    url: "/transaction/deposit/request",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export const requestUserWithdraw = async (data) => {
  const res = await AuthRequest({
    baseURL: BASE_API_URL2,
    url: "/transaction/withdraw/request",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};
